document.addEventListener('DOMContentLoaded', function() {

  if (navigator.share) {
    const shareButton = document.querySelector('[data-navigator-share]');
    shareButton.classList.remove('hidden');
    shareButton.addEventListener('click', function() {
      navigator.share(
        JSON.parse(shareButton.getAttribute('data-navigator-share'))
      );
    });
  }

});
