document.addEventListener('DOMContentLoaded', function() {

  // TODO: 'data-toggle-hide'

  document.querySelectorAll('[data-unhide]').forEach(function(element) {
    element.addEventListener('click', function(event) {
      const target = document.querySelector(element.getAttribute('data-unhide'));
      target.classList.remove('hidden');
    });
  });

  document.querySelectorAll('[data-hide]').forEach(function(element) {
    element.addEventListener('click', function(event) {
      const target = document.querySelector(element.getAttribute('data-hide'));
      target.classList.add('hidden');
    });
  });

  document.querySelectorAll('[data-stat-click]').forEach(function(element) {
    element.addEventListener('click', function(event) {
      const url = element.getAttribute('data-stat-click');
      const token = document.querySelector('meta[name=csrf-token]').content;
      fetch(url, {method: 'POST', headers: {'X-CSRF-Token': token }});
    });
  });

});
